function setCursor(fabricObject, bgImg, cursor) {
  if (fabricObject) {
    fabricObject.setCursor(cursor)
    fabricObject.set('defaultCursor', cursor)
    fabricObject.set('hoverCursor', cursor)
  }
  if (bgImg) {
    bgImg.set('defaultCursor', cursor)
    bgImg.set('hoverCursor', cursor)
  }
}

export default {
  setCursor,
}
