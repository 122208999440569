import { fabric } from 'fabric'

export class CustomCorner {
  constructor(fabricCanvasObject) {
    // set corner default design
    fabric.Object.prototype.cornerColor = '#fff'
    fabric.Object.prototype.cornerSize = 5
    fabric.Object.prototype.transparentCorners = false
    fabric.Object.prototype.setControlsVisibility({ mtr: false })

    // custom tr corner
    const deleteIconImg = require('@/assets/boxClose.svg')
    const imgElement = document.createElement('img')
    imgElement.src = deleteIconImg

    function deleteBoxAction(eventData, transform) {
      const target = transform.target
      const canvas = target.canvas
      target.model.remove(fabricCanvasObject.fabricObject, fabricCanvasObject.boxObjects)
      fabricCanvasObject.updateEmitter.emit('updateBox', fabricCanvasObject.makeBackupJSON(fabricCanvasObject.boxObjects))
      canvas.requestRenderAll()
    }

    function renderIcon(ctx, left, top, styleOverride, fabricObject) {
      const size = 24
      ctx.save()
      ctx.translate(left, top)
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
      ctx.drawImage(imgElement, -size / 2, -size / 2, size, size)
      ctx.restore()
    }

    fabric.Object.prototype.controls.tr = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetX: 3,
      offsetY: 0,
      sizeX: 24,
      sizeY: 24,
      touchSizeX: 24,
      touchSizeY: 24,
      actionName: 'delete',
      cursorStyle: 'pointer',
      mouseUpHandler: deleteBoxAction,
      render: renderIcon,
    })
  }
}
