export default Object.freeze({
  FPS_INTERVAL: 100,
  IMAGE_CACHE_SIZE: 150,
  NOT_PLAYED_INDEX: -1,
  STOP_STATE: 'STOP',
  PLAY_STATE: 'PLAY',
  PAUSE_STATE: 'PAUSE',
  DISABLE_STATE: 'DISABLE',
  MOUSE_CROSS: 'crosshair',
  MOUSE_POINTER: 'pointer',
  MOUSE_DEFAULT: 'default',
})
