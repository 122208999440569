<template>
  <div class="player-container">
    <div class="wrapper" @contextmenu="onContextMenu" @mousedown="onMouseDown" data-testid="Main_Stream_Box">
      <Loading v-if="playerState === CONSTANT.DISABLE_STATE" />
      <canvas ref="fabric"></canvas>
      <PlayerZoom @click="onClickZoom" :zoom="zoom" />
      <ContextMenu v-click-outside="closeContextMenu" :data="contextmenu" @mousedown="onDownloadFrameImage" :width="120">Save Image</ContextMenu>
    </div>
    <slot
      :currentIndex="currentIndex"
      :playerState="playerState"
      :moveFrame="moveFrame"
      :imageUrlList="imageUrlList"
      :detectedImageIndex="detectedImageIndex"
      :hasAfterImages="hasAfterImages"
      :play="play"
      :pause="pause"
      :currentLogId="currentLogId"
    ></slot>
  </div>
</template>

<script>
import Loading from '@common/ImagePlayer/Loading'
import FabricCanvas from '@common/newImage/canvas/FabricCanvas'
import Util from '@/util'
import PlayerZoom from '@common/ImagePlayer/PlayerZoom'
import ContextMenu from '@common/ImagePlayer/ContextMenu'
import FileSaver from 'file-saver'
import CONSTANT from '@common/newImage/canvas/constant'

export default {
  components: {
    PlayerZoom,
    ContextMenu,
    Loading,
  },
  props: {
    currentLog: Object,
  },
  data() {
    return {
      CONSTANT,
      playerState: CONSTANT.PAUSE_STATE,
      fabricObject: null,
      imageUrlList: [],
      resizingTimerId: null,
      currentIndex: 0,
      entireImageLength: 0,
      detectedImageIndex: -1,
      zoom: 1,
      contextmenu: { x: 0, y: 0, display: false },
    }
  },
  watch: {
    currentLog(nextLog, prevLog) {
      if ((prevLog && nextLog && nextLog.log_id !== prevLog.log_id) || (!prevLog && nextLog && nextLog.log_id)) {
        this.canvasUpdate()
      } else if (nextLog.log_id === prevLog.log_id && prevLog.after_image_paths === null && nextLog.after_image_paths !== null) {
        this.updateAfterImagePath()
      } else if (!nextLog?.log_id) {
        this.resetCanvas()
      }
    },
  },
  computed: {
    hasAfterImages() {
      if (this.currentLog) return !!this.currentLog.after_image_paths?.length
      return false
    },
    currentLogId() {
      if (this.currentLog) return this.currentLog.log_id
      return -1
    },
  },
  mounted() {
    if (this.currentLog) this.canvasInitialize()
  },
  destroyed() {
    window.removeEventListener('resize', this.canvasResizing)
  },
  methods: {
    canvasInitialize() {
      if (!this.currentLog?.before_image_paths) return
      const convertedBeforeImages = this.currentLog.before_image_paths.map((img) => Util.getImageUrl(img))
      const convertedAfterImages = !this.currentLog.after_image_paths ? [] : this.currentLog.after_image_paths.map((img) => Util.getImageUrl(img))
      const convertedetectImage = Util.getImageUrl(this.currentLog.detected_image_path)
      const frameUpdateCallback = (updatedCurrentIndex) => (this.currentIndex = updatedCurrentIndex)
      const stateUpdateCallback = (state) => (this.playerState = state)
      const detectionObjects = this.currentLog.objects.map((object) => ({
        ...object,
        type: 'Box',
        logId: this.currentLog.log_id,
      }))
      this.fabricObject = new FabricCanvas(
        this.$refs.fabric,
        convertedBeforeImages,
        convertedAfterImages,
        convertedetectImage,
        detectionObjects,
        frameUpdateCallback,
        stateUpdateCallback,
        false
      )
      window.addEventListener('resize', this.canvasResizing)
    },
    resetCanvas() {
      if (this.fabricObject) this.fabricObject.playerClear()
      this.fabricObject = null
      this.imageUrlList = []
      this.resizingTimerId = null
      this.currentIndex = 0
      this.entireImageLength = 0
      this.detectedImageIndex = -1
      this.zoom = 1
      this.playerState = CONSTANT.PAUSE_STATE
      this.contextmenu = { x: 0, y: 0, display: false }
      window.removeEventListener('resize', this.canvasResizing)
    },
    // ImagePlayer Update
    canvasUpdate() {
      this.resetCanvas()
      this.canvasInitialize()
    },
    async updateAfterImagePath() {
      const convertedAfterImages = this.currentLog.after_image_paths.map((img) => Util.getImageUrl(img))
      const afterImageUrls = await this.fabricObject.updateAfterImagePath(convertedAfterImages)
      this.imageUrlList = [...this.imageUrlList.concat(afterImageUrls)]
      this.entireImageLength = this.imageUrlList.length
    },
    canvasResizing() {
      if (this.resizingTimerId) clearTimeout(this.resizingTimerId)
      this.resizingTimerId = setTimeout(() => {
        this.fabricObject.playerPause()
        this.fabricObject.canvasResizing()
      }, 500)
    },
    // ImagePlayer Control
    async play() {
      this.playerState = CONSTANT.DISABLE_STATE
      const loadedImagesInfo = await this.fabricObject.preLoadImageForPlay()
      this.imageUrlList = loadedImagesInfo.precessedImageObjects.map((imageValue) => imageValue.imageUrl)
      this.entireImageLength = loadedImagesInfo.wholeImageLength
      this.detectedImageIndex = loadedImagesInfo.detectedImageIndex
      const frameUpdateCallback = (updatedCurrentIndex) => (this.currentIndex = updatedCurrentIndex)
      const stateUpdateCallback = (state) => (this.playerState = state)
      this.$nextTick(() => {
        this.fabricObject.playPlayer(frameUpdateCallback, stateUpdateCallback)
      })
    },
    pause() {
      this.fabricObject.playerPause()
    },
    moveFrame(frameIndex) {
      if (this.playerState === CONSTANT.STOP_STATE) this.playerState = CONSTANT.PAUSE_STATE
      this.fabricObject.moveFrame(frameIndex)
    },
    // Image Player Zoom
    onClickZoom() {
      switch (this.zoom) {
        case 1:
          this.zoom = 1.5
          break
        case 1.5:
          this.zoom = 2
          break
        default:
          this.zoom = 1
          break
      }
      this.fabricObject.setZoom(this.zoom)
    },
    // Image Save
    closeContextMenu() {
      this.contextmenu = { x: 0, y: 0, display: false }
    },
    onMouseDown() {
      this.closeContextMenu()
    },
    onContextMenu(event) {
      event.preventDefault()
      const secondaryButton = 2
      if (event.button === secondaryButton && this.imageUrlList.length) {
        this.contextmenu = { x: event.x, y: event.y, display: true }
      } else {
        this.closeContextMenu()
      }
    },
    onDownloadFrameImage(event) {
      const primaryButton = 0
      if (event.button === primaryButton) {
        const src = this.imageUrlList[this.currentIndex] + '?nocache=' + Date.now()
        const srcSplitDot = this.imageUrlList[this.currentIndex].split('.')
        const ext = srcSplitDot[srcSplitDot.length - 1]
        let date = Util.getOffsetDate(this.currentLog.created_at, this.currentLog.offset)
        date = date.replace(/:/g, '-')
        const newFileName = `${date}_${this.currentIndex}.${ext}`
        FileSaver.saveAs(src, newFileName)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.player-container {
  width: 100%;
  height: 100%;
}
.wrapper {
  background-color: black;
  height: calc(100% - 74px);
  position: relative;
  overflow: hidden;
}

.alert-player {
  .wrapper {
    min-height: 430px;
    height: calc(100% - 204px);
    border-radius: 20px;
  }
}
</style>
