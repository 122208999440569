import { fabric } from 'fabric'

export default class Box {
  constructor(object) {
    this.x = object.x || 0
    this.y = object.y || 0
    this.width = object.width || 0
    this.height = object.height || 0
    this.selectable = object.selectable
    this.visible = object.visible
    this.logId = object.logId || 0
  }

  draw(canvas) {
    if (!this.rectObject && this.width > 0 && this.height > 0) {
      this.rectObject = new fabric.Rect({
        width: this.width,
        height: this.height,
        top: this.y,
        left: this.x,
        rx: 3,
        ry: 3,
        stroke: '#F9423A',
        fill: '',
        strokeWidth: 3,
        strokeUniform: true,
        hasRotatingPoint: false,
        hoverCursor: 'pointer',
        selectable: this.selectable,
      })
      this.rectObject.set('visible', this.visible)
      canvas.add(this.rectObject)
      this.rectObject.model = this
    }
  }

  remove(canvas, shapes) {
    if (shapes) {
      const index = shapes.indexOf(this)
      if (index !== -1) {
        shapes.splice(index, 1)
      }
    }
    if (this.rectObject) {
      canvas.remove(this.rectObject)
    }
  }

  shapedUpdate(tlX, tlY, brX, brY) {
    // If the box line is correctly aligned with the image area, the line may escape from the image.
    // This shows poor design, reducing the box size by 3px so that the box line enters the image area.
    const pixelCalibrationValue = 3
    const bgImgAreaWidth = brX - tlX - pixelCalibrationValue
    const bgImgAreaHeight = brY - tlY - pixelCalibrationValue

    const scaledWidth = this.rectObject.width * this.rectObject.scaleX
    const scaledHeight = this.rectObject.height * this.rectObject.scaleY

    if (scaledHeight > bgImgAreaHeight) {
      const maxScaleY = bgImgAreaHeight / this.rectObject.height
      this.rectObject.set('scaleY', maxScaleY)
    }

    if (scaledWidth > bgImgAreaWidth) {
      const maxScaleX = bgImgAreaWidth / this.rectObject.width
      this.rectObject.set('scaleX', maxScaleX)
    }

    this.width = this.rectObject.width * this.rectObject.scaleX
    this.height = this.rectObject.height * this.rectObject.scaleY

    const top = this.rectObject.top
    const bottom = top + this.height
    const left = this.rectObject.left
    const right = left + this.width

    // Bottom Move
    if (bottom > brY) {
      this.rectObject.set('top', brY - this.height - pixelCalibrationValue)
    }

    // Top Move
    if (top < tlY) {
      this.rectObject.set('top', tlY)
    }

    // Right Move
    if (right > brX) {
      this.rectObject.set('left', brX - this.width - pixelCalibrationValue)
    }

    // left Move
    if (left < tlX) {
      this.rectObject.set('left', tlX)
    }

    this.x = this.rectObject.left
    this.y = this.rectObject.top
  }
}
