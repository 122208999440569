import { fabric } from 'fabric'
import Box from './Box'
import CONSTANT from './constant'
import { transFromDataToBox, transFromBoxToData } from './util'
import ZoomPanning from './ZoomPanning'
import { CustomCorner } from './CustomCorner'
import GlobalControl from './GlobalControl'
import { EventEmitter } from 'events'

export default class FabricCanvas {
  // Class instance, canvas DOM
  canvasDomElement
  fabricObject
  zoomPanningObject
  // not Converted Image
  detectedImage
  afterImages
  beforeImages
  // Converted Image
  fabricImageObjects
  // Image Cache
  imageCache = new Map()
  // Player Image Index
  playingIndex = CONSTANT.NOT_PLAYED_INDEX
  detectImageIndex = 0
  endIndex = 0
  // Player State
  playerState = CONSTANT.STOP_STATE
  // animation ID
  animationRequest
  // view와 연동을 위한 index callback function
  updateFrameCallback
  updateStateCallback
  // Box를 더 빨리 그리기 위해 JSON 형식으로 백업
  boxJsonBackup
  boxObjects = []
  boxDrawingEvent = false
  // 감지된 이미지
  detectionBGImage
  isDraw = false
  updateEmitter = new EventEmitter()

  constructor(canvasDomElement, beforeImages, afterImages, detectedImage, detectionBoxes, updateFrameCallback, updateStateCallback, boxDrawingEvent) {
    this.canvasDomElement = canvasDomElement
    this.beforeImages = beforeImages
    this.afterImages = afterImages
    this.detectedImage = detectedImage
    this.boxJsonBackup = detectionBoxes
    this.updateFrameCallback = updateFrameCallback
    this.updateStateCallback = updateStateCallback
    this.boxDrawingEvent = boxDrawingEvent
    this.initialize(canvasDomElement)
    this.addEventOnCanvas()
  }

  async initialize(canvasDomElement) {
    this.fabricObject = new fabric.Canvas(canvasDomElement, {
      width: canvasDomElement.parentNode.clientWidth,
      height: canvasDomElement.parentNode.clientHeight,
      hasRotatingPoint: false,
    })
    await this.drawThumbnail(this.detectedImage)
    this.zoomPanningObject = new ZoomPanning(this.fabricObject)
  }
  // event
  addEventOnCanvas() {
    if (this.boxDrawingEvent) {
      new CustomCorner(this)
      this.fabricObject.on('mouse:down', this.drawBox.bind(this))
      this.fabricObject.on('mouse:up', this.makeBox.bind(this))
      this.fabricObject.on('mouse:wheel', this.onMouseWheel.bind(this))
    } else {
      this.fabricObject.on('mouse:down', this.onMouseDown.bind(this))
      this.fabricObject.on('mouse:move', this.onMouseMove.bind(this))
      this.fabricObject.on('mouse:up', this.onMouseUp.bind(this))
    }
  }

  onMouseWheel(event) {
    this.zoomPanningObject.processZoom(event)
    this.fabricObject.requestRenderAll()
  }

  drawBox(event) {
    this.downX = event.absolutePointer.x
    this.downY = event.absolutePointer.y
  }

  getDetectionBGImageCoords() {
    const tlX = this.detectionBGImage.aCoords.tl.x
    const tlY = this.detectionBGImage.aCoords.tl.y
    const brX = this.detectionBGImage.aCoords.br.x
    const brY = this.detectionBGImage.aCoords.br.y
    return { tlX, tlY, brX, brY }
  }

  isValidToMakeBox(startX, startY, endX, endY) {
    const { tlX, tlY, brX, brY } = this.getDetectionBGImageCoords()

    const startPointerValid = startX >= tlX && startX <= brX && startY >= tlY && startY <= brY
    const endPointerValid = endX >= tlX && endX <= brX && endY >= tlY && endY <= brY

    return startPointerValid && endPointerValid ? true : false
  }

  makeBox(event) {
    const mouseUpPointerX = event.absolutePointer.x
    const mouseUpPointerY = event.absolutePointer.y

    if (!this.isDraw && event.transform && event.transform.action) {
      if (event.transform.action.startsWith('scale') || event.transform.action.startsWith('drag')) {
        const { tlX, tlY, brX, brY } = this.getDetectionBGImageCoords()
        event.target.model.shapedUpdate(tlX, tlY, brX, brY)
        this.boxJsonBackup = this.makeBackupJSON(this.boxObjects)
        this.updateEmitter.emit('updateBox', this.boxJsonBackup)
      }
    } else if (this.isDraw) {
      const width = Math.abs(mouseUpPointerX - this.downX)
      const height = Math.abs(mouseUpPointerY - this.downY)
      const validToMakeBox = this.isValidToMakeBox(this.downX, this.downY, mouseUpPointerX, mouseUpPointerY)
      if (width && height && validToMakeBox) {
        const box = new Box({
          x: this.downX < mouseUpPointerX ? this.downX : mouseUpPointerX,
          y: this.downY < mouseUpPointerY ? this.downY : mouseUpPointerY,
          width,
          height,
          selectable: true,
        })
        this.boxObjects.push(box)
        box.draw(this.fabricObject)
        this.brintToFrontShapes()
        this.boxJsonBackup = this.makeBackupJSON(this.boxObjects)
        this.updateEmitter.emit('updateBox', this.boxJsonBackup)
      }
      this.fabricObject.discardActiveObject()
    }
  }

  boxUpdateHandler(handler) {
    this.updateEmitter.on('updateBox', handler)
  }

  onMouseDown(event) {
    this.zoomPanningObject.onMouseDown(event)
  }

  onMouseMove(event) {
    this.zoomPanningObject.onMouseGrabMove(event)
    this.fabricObject.renderAll()
  }

  onMouseUp(event) {
    this.zoomPanningObject.onMouseUp(event)
  }

  canvasResizing() {
    const width = this.canvasDomElement.parentNode.parentNode.clientWidth
    const height = this.canvasDomElement.parentNode.parentNode.clientHeight
    this.fabricObject.setDimensions({
      width,
      height,
    })
    this.boxObjects.forEach((box) => this.fabricObject.remove(box.rectObject))
    this.fabricObject.remove(this.detectionBGImage)

    if (this.fabricImageObjects) {
      this.resizeAllImages()
      if (this.playingIndex === CONSTANT.NOT_PLAYED_INDEX) {
        this.setBoxShapes(this.boxJsonBackup, true)
        this.fabricImageObjects[this.detectImageIndex].set('visible', true)
        this.updatePlayerState(CONSTANT.STOP_STATE)
      } else if (this.playingIndex === this.detectImageIndex) {
        this.setBoxShapes(this.boxJsonBackup, true)
        this.fabricImageObjects[this.detectImageIndex].set('visible', true)
      } else {
        this.setBoxShapes(this.boxJsonBackup, false)
        this.fabricImageObjects[this.playingIndex].set('visible', true)
      }
    } else {
      this.drawThumbnail(this.detectedImage)
    }
  }

  resizeAllImages() {
    this.fabricObject.remove(...this.fabricImageObjects)
    this.fabricImageObjects.forEach((imageObj) => {
      this.fitImageToCanvas(imageObj)
      imageObj.set('visible', false)
    })
    this.fabricObject.add(...this.fabricImageObjects)
  }

  setZoom(zoom) {
    this.zoomPanningObject.setZoom(zoom)
    this.fabricObject.requestRenderAll()
  }

  setDraw(isDraw) {
    this.isDraw = isDraw
    if (isDraw) {
      fabric.Object.prototype.evented = false
      GlobalControl.setCursor(this.fabricObject, this.detectionBGImage, CONSTANT.MOUSE_CROSS)
      // this.disabledActiveObject()
    } else {
      fabric.Object.prototype.evented = true
      GlobalControl.setCursor(this.fabricObject, this.detectionBGImage, CONSTANT.MOUSE_DEFAULT)
    }
  }

  async updateAfterImagePath(afterImages) {
    this.playerPause()
    this.afterImages = afterImages
    const afterImageObjects = await this.importAllImages(this.afterImages)
    const precessedAfterImages = afterImageObjects
      .filter((imageValue) => imageValue.imageObj !== null)
      .map((imageValue) => {
        this.fitImageToCanvas(imageValue.imageObj)
        return imageValue.imageObj
      })

    this.fabricImageObjects = [...this.fabricImageObjects, ...precessedAfterImages]
    this.endIndex = this.fabricImageObjects.length - 1

    return afterImageObjects
      .filter((imageValue) => imageValue.imageObj !== null)
      .map((imageValue) => {
        return imageValue.imageUrl
      })
  }

  async drawThumbnail(imageUrl) {
    await fabric.Image.fromURL(imageUrl, (image) => {
      image.set('selectable', false)
      image.set('hoverCursor', 'default')
      image.set('moveCursor', 'pointer')
      this.fitImageToCanvas(image)
      this.fabricObject.add(image)
      this.detectionBGImage = image
      this.setBoxShapes(this.boxJsonBackup, true)
      this.zoomPanningObject.setBGImg(image)
      this.fabricObject.renderAll()
    })
  }

  fitImageToCanvas(ImageObj) {
    const canvasW = this.fabricObject.get('width')
    const canvasH = this.fabricObject.get('height')
    const ratioW = canvasW / ImageObj.width
    const ratioH = canvasH / ImageObj.height
    if (ratioW < ratioH) ImageObj.scale(ratioW)
    else ImageObj.scale(ratioH)

    this.fabricObject.centerObject(ImageObj)
  }

  loadImage(imageUrl) {
    if (this.imageCache.has(imageUrl)) {
      this.imageCache.get(imageUrl).hit++
      this.imageCache.get(imageUrl).date = Date.now()
      return Promise.resolve({
        imageObj: this.imageCache.get(imageUrl).image,
        imageUrl,
      })
    } else {
      return new Promise((resolve) => {
        fabric.Image.fromURL(imageUrl, (imageObj) => {
          if (imageObj.width && imageObj.height) {
            imageObj.set('selectable', false)
            imageObj.set('hoverCursor', 'default')
            imageObj.set('visible', false)
            this.cacheImages(imageUrl, imageObj)
            resolve({ imageObj, imageUrl })
          } else resolve({ imageObj: null, imageUrl })
        })
      })
    }
  }

  // playPlayer 메서드를 실행하기 이전에 이미지로드를 위한 작업
  async preLoadImageForPlay() {
    return new Promise(async (resolve) => {
      const beforeImageObjects = await this.importAllImages(this.beforeImages)
      const afterImageObjects = await this.importAllImages(this.afterImages)
      const detectImageObject = await this.loadImage(this.detectedImage)
      resolve(this.organizeImage(beforeImageObjects, afterImageObjects, detectImageObject))
    })
  }

  // 필요한 이미지를 모두 사전에 불러옵니다.
  importAllImages(imageUrls) {
    return Promise.all(imageUrls.map((url) => this.loadImage(url)))
  }

  // Vue에서 사용할 이미지를 정리하고 반환합니다.
  organizeImage(before, after, detect) {
    const precessedBeforeImages = before.filter((imageValue) => imageValue.imageObj !== null)
    const precessedAfterImages = after.filter((imageValue) => imageValue.imageObj !== null)
    this.fabricImageObjects = [...precessedBeforeImages, detect, ...precessedAfterImages].map((imageValue) => {
      this.fitImageToCanvas(imageValue.imageObj)
      return imageValue.imageObj
    })
    this.endIndex = precessedBeforeImages.length + precessedAfterImages.length - 1
    this.detectImageIndex = precessedBeforeImages.length

    return {
      wholeImageLength: this.endIndex,
      detectedImageIndex: this.detectImageIndex,
      precessedImageObjects: [...precessedBeforeImages, detect, ...precessedAfterImages],
    }
  }
  // detection box draw
  setBoxShapes(boxes, visible) {
    this.boxObjects = []
    boxes.forEach((object) => {
      const obj = transFromDataToBox(object, this.canvasDomElement, this.detectionBGImage)
      const box = new Box({ ...obj, selectable: this.boxDrawingEvent, visible })
      this.boxObjects.push(box)
      box.draw(this.fabricObject)
    })
    this.boxJsonBackup = this.makeBackupJSON(this.boxObjects)
  }

  makeBackupJSON(boxShapes) {
    return boxShapes.map((shape) => {
      return transFromBoxToData(shape, this.canvasDomElement, this.detectionBGImage, shape.logId)
    })
  }

  brintToFrontShapes() {
    this.boxObjects.forEach((shape) => {
      if (shape.rectObject) {
        shape.rectObject.set('visible', true)
        shape.rectObject.bringToFront()
      }
    })
  }
  hideBoxes() {
    this.boxObjects.forEach((shape) => {
      if (shape.rectObject) shape.rectObject.set('visible', false)
    })
  }

  // controller
  moveFrame(frameIndexToChange) {
    if (this.playerState === CONSTANT.PLAY_STATE) this.playerPause()
    if (this.fabricImageObjects[this.playingIndex - 1]) {
      this.fabricImageObjects[this.playingIndex - 1].set('visible', false)
    }
    if (this.fabricImageObjects[frameIndexToChange]) {
      this.fabricImageObjects[frameIndexToChange].set('visible', true)
      this.fabricObject.add(this.fabricImageObjects[frameIndexToChange])
      if (this.detectImageIndex === frameIndexToChange) this.brintToFrontShapes()
      this.fabricObject.renderAll()
      this.updatePlayingIndex(frameIndexToChange)
    }
  }

  playPlayer(frameUpdateCallback) {
    if (this.playerState === CONSTANT.PLAY_STATE) return
    this.updateFrameCallback = frameUpdateCallback
    this.fabricObject.remove(...this.fabricImageObjects)
    this.fabricObject.add(...this.fabricImageObjects)
    this.playerState = CONSTANT.PLAY_STATE
    this.updateStateCallback(this.playerState)
    this.startAnimation()
  }

  startAnimation() {
    let startTime = Date.now()
    this.hideBoxes()
    const performAnimation = () => {
      const now = Date.now()
      const elapsed = now - startTime

      if (elapsed > CONSTANT.FPS_INTERVAL) {
        startTime = now - (elapsed % CONSTANT.FPS_INTERVAL)
        this.frameChange(++this.playingIndex)
        if (this.playingIndex === this.endIndex) {
          this.fabricImageObjects[this.endIndex].set('visible', false)
          this.fabricObject.requestRenderAll()
          this.frameChange(this.detectImageIndex)
          this.updatePlayingIndex(CONSTANT.NOT_PLAYED_INDEX)
          this.updatePlayerState(CONSTANT.STOP_STATE)
        } else {
          if (this.playingIndex === this.detectImageIndex && this.playerState !== CONSTANT.PAUSE_STATE) {
            this.updatePlayerState(CONSTANT.PAUSE_STATE)
          }
          this.updatePlayingIndex(this.playingIndex)
        }
      }
      if (this.playerState === CONSTANT.PLAY_STATE) {
        this.animationRequest = fabric.util.requestAnimFrame(performAnimation)
      }
    }
    this.animationRequest = fabric.util.requestAnimFrame(performAnimation)
  }

  frameChange(frameIndexToChange) {
    if (this.fabricImageObjects[this.playingIndex]) {
      this.fabricImageObjects[this.playingIndex].set('visible', false)
      this.fabricObject.requestRenderAll()
    }
    if (this.fabricImageObjects[frameIndexToChange]) {
      this.fabricImageObjects[frameIndexToChange].set('visible', true)
      this.fabricObject.add(this.fabricImageObjects[frameIndexToChange])
      if (frameIndexToChange === this.detectImageIndex) this.brintToFrontShapes()
      this.fabricObject.requestRenderAll()
    } else {
      this.playerState = CONSTANT.STOP_STATE
      this.updateStateCallback(this.playerState)
      this.updatePlayingIndex(CONSTANT.NOT_PLAYED_INDEX)
      cancelAnimationFrame(this.animationRequest)
    }
  }

  playerPause() {
    this.playerState = CONSTANT.PAUSE_STATE
    this.updateStateCallback(this.playerState)
    cancelAnimationFrame(this.animationRequest)
    this.manageCacheSize()
  }

  playerClear() {
    this.playerState = CONSTANT.STOP_STATE
    this.updateStateCallback(this.playerState)
    this.fabricObject.clear()
    this.fabricObject.dispose()
  }

  updatePlayerState(state) {
    this.playerState = state
    this.updateStateCallback(this.playerState)
  }

  updatePlayingIndex(index) {
    this.playingIndex = index
    this.updateFrameCallback(this.playingIndex)
  }

  // image cache
  cacheImages(url, image) {
    this.imageCache.set(url, { hit: 0, date: Date.now(), image })
  }
  manageCacheSize() {
    if (this.imageCache.size > CONSTANT.IMAGE_CACHE_SIZE) {
      const cachedImages = []
      for (const entry of this.imageCache.entries()) {
        cachedImages.push(entry)
      }
      // select hit & date is low
      cachedImages.sort((a, b) => a[1].hit - b[1].hit)
      cachedImages.sort((a, b) => a[1].date - b[1].date)
      const removeItems = cachedImages.slice(0, this.imageCache.size - CONSTANT.IMAGE_CACHE_SIZE).filter((item) => !this.imagePath.includes(item[0]))
      const removeImages = removeItems.map((item) => item[1].image)
      this.fabricObject.remove(...removeImages)
      removeItems.forEach((item) => {
        this.imageCache.delete(item[0])
        item[1].image.dispose()
      })
    }
  }
}
