import CONSTANT from './constant'

export default class ZoomPanning {
  zoom = 1
  bgImg
  fabricObject
  lastPosX
  lastPosY
  cursor
  maxZoom = 5
  minZoom = 1

  constructor(fabricObject) {
    this.fabricObject = fabricObject
  }

  setBGImg(bgImg) {
    this.bgImg = bgImg
    this.setZoom(1)
  }

  setZoom(zoom) {
    this.zoom = zoom
    const { left, top } = this.fabricObject.getCenter()
    const pointer = { x: left, y: top }
    this.fabricObject.zoomToPoint(pointer, this.zoom)
    this.checkViewportBoundary()
  }

  onMouseDown(event) {
    this.cursor = CONSTANT.MOUSE_POINTER
    this.fabricObject.setCursor(CONSTANT.MOUSE_POINTER)
    this.lastPosX = event.e.clientX
    this.lastPosY = event.e.clientY
    this.fabricObject.set('selection', false)
  }

  onMouseUp() {
    this.cursor = CONSTANT.MOUSE_DEFAULT
    this.fabricObject.setCursor(CONSTANT.MOUSE_DEFAULT)
  }

  onMouseGrabMove(event) {
    if (this.cursor === CONSTANT.MOUSE_POINTER) {
      this.fabricObject.setCursor(CONSTANT.MOUSE_POINTER)
      var vpt = this.fabricObject.viewportTransform
      vpt[4] += event.e.clientX - this.lastPosX
      vpt[5] += event.e.clientY - this.lastPosY

      this.lastPosX = event.e.clientX
      this.lastPosY = event.e.clientY
      this.checkViewportBoundary()
    }
  }

  processZoom(e) {
    if (e.e.deltaY > 0 && this.maxZoom > this.zoom) {
      this.zoom += 0.1
      this.zoom = +this.zoom.toFixed(1)
      this.fabricObject.zoomToPoint(e.pointer, this.zoom)
    } else if (this.minZoom < this.zoom) {
      this.zoom -= 0.1
      this.zoom = +this.zoom.toFixed(1)
      this.fabricObject.zoomToPoint(e.pointer, this.zoom)
    }
    e.e.preventDefault()
    e.e.stopPropagation()
    this.checkViewportBoundary()
  }

  checkViewportBoundary() {
    if (this.bgImg) {
      let vpt = this.fabricObject.viewportTransform
      let cw = this.fabricObject.getWidth()
      let ch = this.fabricObject.getHeight()
      let bgw = +this.bgImg.getScaledWidth().toFixed()
      let bgh = +this.bgImg.getScaledHeight().toFixed()
      let offsetX = ((cw - bgw) / 2) * this.zoom
      let offsetY = ((ch - bgh) / 2) * this.zoom
      if (this.zoom <= 1) {
        vpt[4] = 0
        vpt[5] = 0
      } else {
        // over left
        if (vpt[4] > -offsetX) {
          vpt[4] = -offsetX
        }
        // over up
        if (vpt[5] > -offsetY) {
          vpt[5] = -offsetY
        }
        // over right
        if (vpt[4] < cw - bgw * this.zoom - offsetX) {
          vpt[4] = cw - bgw * this.zoom - offsetX
        }
        // over down
        if (vpt[5] < ch - bgh * this.zoom - offsetY) {
          vpt[5] = ch - bgh * this.zoom - offsetY
        }
      }
    }
  }
}
