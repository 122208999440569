<template>
  <div class="circle-button">
    <div v-if="playerState === CONSTANT.PAUSE_STATE" @click="onClickPlay" class="icon-container" :style="backgroundColor">
      <img src="@/assets/play.svg" />
    </div>
    <div v-else-if="playerState === CONSTANT.PLAY_STATE" @click="onClickPause" class="icon-container" :style="backgroundColor">
      <img src="@/assets/pause.svg" />
    </div>
    <div v-else-if="playerState === CONSTANT.STOP_STATE" @click="onClickPlay" class="icon-container" :style="backgroundColor">
      <img src="@/assets/playback.svg" />
    </div>
    <div v-else class="icon-container disabled">
      <img src="@/assets/play_disabled.svg" />
    </div>
  </div>
</template>

<script>
import CONSTANT from '@common/newImage/canvas/constant'
export default {
  props: {
    isWorkspace: {
      type: Boolean,
      default: false,
    },
    playerState: {
      type: String,
      default: CONSTANT.PAUSE_STATE,
    },
  },
  data() {
    return {
      CONSTANT,
    }
  },
  computed: {
    backgroundColor() {
      if (this.isWorkspace) return { backgroundColor: '#3d51fb' }
      else return { backgroundColor: '#ff5b54' }
    },
  },
  methods: {
    onClickPlay() {
      this.$emit('play')
    },
    onClickPause() {
      this.$emit('pause')
    },
  },
}
</script>

<style lang="scss" scoped>
.circle-button {
  cursor: pointer;
  width: 34px;
  height: 34px;
  margin-right: 16px;
  img {
    margin-left: 3px;
  }
}

.icon-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &.disabled {
    background-color: #cbcbcb;
    color: #00000042;
  }
}
</style>
